const state = {
  isShowingSlideOver: false,
  isShowingModals: false,
}

const getters = {}

const mutations = {
  showSlideOver: state => (state.isShowingSlideOver = true),
  hideSlideOver: state => (state.isShowingSlideOver = false),
  hideModals: state => (state.isShowingModals = false),
  showModals: state => (state.isShowingModals = true),
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
