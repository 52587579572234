import { router } from "@inertiajs/vue3"

export const zRoute = window.route
/**
 * paginationMeta returns object with
 * @param  {object} paginator
 * @return {object} {
      "current_page":1,
      "from":1,
      "last_page":2,
      "links":[
        {"url":null,"label":"&laquo; Previous","active":false},
        {"url":"/","label":"1","active":true},
        {"url":"/","label":"2","active":false},
        {"url":"/","label":"Next &raquo;","active":false}
      ],
      "path":"#",
      "per_page":10,
      "to":10,
      "total":17,
      "first":"#",
      "last":"#",
      "prev":null,
      "next":"#",
    }
 */
export const paginationMeta = paginator => {
  // If we are still on an old direct-models paginator.
  // TODO:: Remove this when converting all API data to resource-models paginator.
  //
  if (paginator.meta === undefined) {
    const metaEntries = Object.entries(paginator)
      .filter(([key]) => key !== "data")
      .map(([key, value]) =>
        key.includes("_page_url") ? [key.replace("_page_url", ""), value] : [key, value],
      )
    return Object.fromEntries(metaEntries)
  }

  return {
    ...paginator.meta,
    ...paginator.links,
  }
}

/**
 * setQueryParam sets query params in the URL
 * @param  {string} param
 * @param  {string} value
 */
export const setQueryParam = (param, value) => {
  const p = router.page
  const url = p.url.split("?")[0]
  p.url = `${url}?${param}=${value}`
  router.setPage(p)
}
/**
 * setQueryParams sets multiple query params in the URL
 * @param  {object} param
 */
export const setQueryParams = (params, conditions) => {
  const str = []
  for (const p in params)
    if (params.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]))
    }
  const queryString = str.join("&")
  const p = router.page
  const url = p.url.split("?")[0]
  p.url = queryString ? `${url}?${queryString}` : url
  router.setPage(p, {
    preserveState: true,
    ...conditions,
  })
}

/**
 * getQueryParam gets query params from the URL
 * @param  {string} param
 */
export const getQueryParam = param => {
  return new URL(document.location).searchParams.get(param)
}

/**
 * parseQueryString parses all the query params and return them as object
 * @return {object}
 */
export const parseQueryString = () => {
  const params = {}
  window.location.search
    .replace(/^\?/, "")
    .split("&")
    .map(val => decodeURI(val))
    .forEach(val => {
      const x = val.split("=")
      const key = x[0]
      const value = x[1]
      // Below regular expression matches array like query string parameter's
      // index, where in a format like: "?categories[0]=3&categories[1]=7"
      // it will match the index within square brackets: "[0]" or "[1]".
      const arrayParamRegex = /\[(.+?)]/
      const filterIsArray = key.match(arrayParamRegex) !== null

      if (filterIsArray) {
        const arrayKey = key.replace(arrayParamRegex, "")
        if (params[arrayKey]) {
          params[arrayKey].push(value)
        } else {
          params[arrayKey] = [value]
        }
      } else if (key) {
        params[key] = value
      }
    })
  return params
}
