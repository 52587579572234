import httpClient from "@/helpers/http"
import { userLang } from "@/helpers/globals"

const state = {
  init: true,
  manager: {
    image: null,
    name: null,
    email: null,
    phone: null,
  },
  loadAll: false,
  articles: [],
  currentRoute: null,
  pageLoading: false,
  managerLoading: true,
  articlesLoading: true,
  articlesPagination: {
    count: 5,
    current_page: 1,
    page_count: 1,
  },
}

const getters = {}

const mutations = {
  setInit(state, boolean) {
    state.init = boolean
  },
  setCurrentRoute(state, route) {
    state.currentRoute = route
  },
  setLoadAll(state, boolean) {
    state.loadAll = boolean
  },
  setManager(state, manager) {
    state.manager = manager
  },
  setArticles(state, articles) {
    state.articles = articles
  },
  pushArticles(state, articles) {
    state.articles.push(articles)
  },
  setPageLoading(state, pageLoading) {
    state.pageLoading = pageLoading
  },
  setManagerLoading(state, managerLoading) {
    state.managerLoading = managerLoading
  },
  setArticlesLoading(state, articlesLoading) {
    state.articlesLoading = articlesLoading
  },
  setArticlesPagination(state, pagination) {
    state.articlesPagination = pagination
  },
}

const actions = {
  init({ commit, dispatch }, route) {
    dispatch("loadAccountManager")
    dispatch("loadRouteArticles", { currentRoute: route })
    commit("setInit", false)
  },
  loadAccountManager({ commit }) {
    httpClient
      .get("/api/support/customer-success-manager")
      .then(response => {
        commit("setManager", response.data)
      })
      .catch(error => {
        console.error(error)
      })
      .then(() => {
        commit("setManagerLoading", false)
      })
  },
  loadRouteArticles({ commit, dispatch, state }, data) {
    commit("setPageLoading", true)
    httpClient
      .get("/api/support/articles/route", {
        params: {
          route: state.currentRoute,
          page: data.page ?? 1,
          locale: userLang,
        },
      })
      .then(response => {
        if (response.data.page_count < 2) {
          commit("setLoadAll", true)
          dispatch("loadAllArticles", { page: 1 })
          commit("setArticles", null)
          if (
            (response.data.articles && response.data.articles.length > 0) ||
            (response.data.results && response.data.results.length > 0)
          ) {
            if (response.data.results && response.data.results.length > 0) {
              commit("setArticles", response.data.results)
            } else if (response.data.articles && response.data.articles.length > 0) {
              commit("setArticles", response.data.articles)
            }
          }
        } else {
          if (
            (response.data.articles && response.data.articles.length > 0) ||
            (response.data.results && response.data.results.length > 0)
          ) {
            if (response.data.results && response.data.results.length > 0) {
              commit("setArticles", response.data.results)
            } else if (response.data.articles && response.data.articles.length > 0) {
              commit("setArticles", response.data.articles)
            }
            commit("setArticlesPagination", {
              count: response.data.count,
              current_page: response.data.page,
              page_count: response.data.page_count,
            })
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
      .then(() => {
        if (!state.loadAll) {
          commit("setArticlesLoading", false)
          commit("setPageLoading", false)
        }
      })
  },
  loadAllArticles({ commit, state }, data) {
    commit("setPageLoading", true)
    httpClient
      .get("/api/support/articles/all", {
        params: {
          page: data.page ?? 1,
          locale: userLang,
        },
      })
      .then(response => {
        if (
          (response.data.articles && response.data.articles.length > 0) ||
          (response.data.results && response.data.results.length > 0)
        ) {
          if (response.data.results && response.data.results.length > 0) {
            if (response.data.page == 1 && state.articles != null) {
              for (let i = 0; i < response.data.results.length; i++) {
                commit("pushArticles", response.data.results[i])
              }
            } else {
              commit("setArticles", response.data.results)
            }
          } else if (response.data.articles && response.data.articles.length > 0) {
            if (response.data.page == 1 && state.articles != null) {
              for (let i = 0; i < response.data.articles.length; i++) {
                commit("pushArticles", response.data.articles[i])
              }
            } else {
              commit("setArticles", response.data.articles)
            }
          }
          commit("setArticlesPagination", {
            count: response.data.count,
            current_page: response.data.page,
            page_count: response.data.page_count,
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
      .then(() => {
        commit("setArticlesLoading", false)
        commit("setPageLoading", false)
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
