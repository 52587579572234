const state = {
  activeItem: null,
}

const getters = {}

const mutations = {
  setActiveItem(state, orderItem) {
    state.activeItem = orderItem
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
