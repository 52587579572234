const state = {
  companyEntities: [],
  departments: [],
  occasions: [],
  users: [],
  request: [],
  filtersAreApplied: false,
  filters: {
    id: "",
    occasions: [],
    departments: [],
    company_entities: [],
    users: [],
    from: "",
    to: "",
    date_from: "",
    date_to: "",
    po_number: "",
    cost_center: "",
    email: "",
    detailed_reference: "",
  },
  filterDefaults: {
    id: "",
    occasions: [],
    departments: [],
    company_entities: [],
    users: [],
    from: "",
    to: "",
    date_from: "",
    date_to: "",
    po_number: "",
    cost_center: "",
    email: "",
    detailed_reference: "",
  },
}

const getters = {
  toSend() {
    const data = {
      id: state.filters.id,
      company_entities: state.filters.company_entities.map(_ => _.id).join(","),
      departments: state.filters.departments.map(_ => _.id).join(","),
      occasions: state.filters.occasions.map(_ => _.id).join(","),
      users: state.filters.users.map(_ => _.id).join(","),
      from: state.filters.from,
      to: state.filters.to,
      date_from: state.filters.date_from,
      date_to: state.filters.date_to,
      po_number: state.filters.po_number,
      cost_center: state.filters.cost_center,
      email: state.filters.email,
      detailed_reference: state.filters.detailed_reference,
    }

    const out = {}

    Object.keys(data).forEach(key => {
      if (data[key]) {
        Object.assign(out, { [key]: data[key] })
      }
    })

    return out
  },

  filterQueryString: (state, { toSend }) => {
    // x: "1" is applied to the query object to prevent a stupid bug in Chrome
    return Object.keys({ ...toSend, x: "1" })
      .map(key => `${key}=${toSend[key]}`)
      .join("&")
  },

  appliedFilters: ({ filters }) => {
    const appliedFilters = []

    if (filters.id) {
      appliedFilters.push({
        label: `Order ID (${filters.id})`,
        key: "id",
      })
    }
    if (filters.departments.length) {
      appliedFilters.push({
        label: `Departments (${filters.departments.length})`,
        key: "departments",
      })
    }
    if (filters.company_entities.length) {
      appliedFilters.push({
        label: `Company Entities (${filters.company_entities.length})`,
        key: "company_entities",
      })
    }
    if (filters.users.length) {
      appliedFilters.push({
        label: `Ordered by (${filters.users.length})`,
        key: "users",
      })
    }
    if (filters.occasions.length) {
      appliedFilters.push({
        label: `Occasions (${filters.occasions.length})`,
        key: "occasions",
      })
    }
    if (filters.date_from || filters.date_to) {
      if (filters.date_from && filters.date_to) {
        appliedFilters.push({
          label: `${filters.date_from} - ${filters.date_to}`,
          key: ["date_from", "date_to"],
        })
      } else if (filters.date_from) {
        appliedFilters.push({
          label: `From ${filters.date_from}`,
          key: "date_from",
        })
      } else if (filters.date_to) {
        appliedFilters.push({ label: `To ${filters.date_to}`, key: "date_to" })
      }
    }
    if (filters.po_number) {
      appliedFilters.push({
        label: `PO Number ${filters.po_number}`,
        key: "po_number",
      })
    }
    if (filters.cost_center) {
      appliedFilters.push({
        label: `Cost Center ${filters.cost_center}`,
        key: "cost_center",
      })
    }
    if (filters.from) {
      appliedFilters.push({ label: `From '${filters.from}'`, key: "from" })
    }
    if (filters.to) {
      appliedFilters.push({ label: `To '${filters.to}'`, key: "to" })
    }
    if (filters.email) {
      appliedFilters.push({ label: `Email ${filters.email}`, key: "email" })
    }
    if (filters.detailed_reference) {
      appliedFilters.push({
        label: `Detailed Reference ${filters.detailed_reference}`,
        key: "detailed_reference",
      })
    }

    return appliedFilters
  },
}

const mutations = {
  setState: (state, { attribute, value }) => {
    if (!Object.keys(state).includes(attribute)) return

    state[attribute] = value
  },

  setFilter: (state, { filter, value }) => {
    if (!Object.keys(state.filters).includes(filter)) return

    state.filters[filter] = value
  },

  filtersApplied: (state, value = true) => (state.filtersAreApplied = value),

  clearFilter: (state, key) => {
    const keys = Array.isArray(key) ? key : [key]
    keys.forEach(key => (state.filters[key] = state.filterDefaults[key]))
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
