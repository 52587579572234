const setStyleProperties = (propertyObject) => {
  for (const key in propertyObject) {
    document.documentElement.style.setProperty(key, propertyObject[key]);
  }
}
export const setThemeStyles = (theme) => { 
  const colors = theme?.colors
  setStyleProperties({
  '--color-brand-primary': colors?.primary_color || '#FF337B',
  '--color-brand-primary-hover': colors?.button_hover_color || '#CC2861',
  '--color-brand-primary-disabled': colors?.button_disabled_color || '#FFEAF1',
  '--color-brand-primary-on': colors?.button_text_color || '#FFFFFF',
})
}