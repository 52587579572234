const state = {
  permissions: [],
  userPermissions: [],
  activePermissions: [],
}

const getters = {
  allPermissionsSelected: state => {
    return state.userPermissions.length >= state.permissions.length
  },
}

const mutations = {
  setPermissions(state, array) {
    state.permissions = array
  },
  clearUserPermissions(state) {
    state.userPermissions = []
  },
  togglePermission(state, id) {
    let index = state.userPermissions.indexOf(id)
    if (index > -1) {
      state.userPermissions.splice(index, 1)
    } else {
      state.userPermissions.push(id)
    }
  },
  addPermission(state, id) {
    let index = state.userPermissions.indexOf(id)
    if (index == -1) state.userPermissions.push(id)
  },
}

const actions = {
  init({ commit, dispatch }, route) {
    dispatch("loadAccountManager")
    dispatch("loadRouteArticles", { currentRoute: route })
    commit("setInit", false)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
