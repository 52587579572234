const state = {
  showingApprovalSidebar: true,
  orderItems: null,
  personalizingItemId: null,
  personalizationData: {},
  product: {},
  signedQuotation: {},
  settings: {},
  order: {},
  locales: [],
  homeDelivery: false,
  companyEntity: {},
  billingAddress: {},
  videoUploadUrl: "",
  removedMessages: [],
}

const getters = {
  personalizingItem: state => {
    if (!state.personalizingItemId) return null

    return state.orderItems.find(item => item.id === state.personalizingItemId)
  },

  personalizationDataForCurrentItem: state => {
    if (!state.personalizingItemId) return {}

    return state.personalizationData[state.personalizingItemId]
  },

  invoicedTo: state => {
    const { companyEntity, billingAddress } = state

    return {
      name: companyEntity.name,
      vat: companyEntity.vat_number,
      street: billingAddress.street,
      number: billingAddress.number,
      bus: billingAddress.bus,
      zipcode: billingAddress.zip_code,
      city: billingAddress.city,
      country: billingAddress.countryName,
    }
  },

  address: state => state.order.address,
}

const mutations = {
  hideApprovalSidebar: state => (state.showingApprovalSidebar = false),
  showApprovalSidebar: state => (state.showingApprovalSidebar = true),

  setPersonalizationData: (state, { id, prop }) => {
    state.personalizationData[id] = {
      ...state.personalizationData[id],
      ...prop,
    }
  },
  addToRemovedMessages: (state, recipientId) => {
    state.removedMessages.push(recipientId)
  },

  setState: (state, { attribute, value }) => (state[attribute] = value),
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
