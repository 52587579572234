import { createI18n } from "vue-i18n"
import Locale from "./vue-i18n-locales.generated.js"

const i18n = createI18n({
  locale: document.documentElement.lang.substr(0, 2),
  globalInjection: true,
  allowComposition: true,
  messages: Locale,
  silentTranslationWarn: true,
  warnHtmlInMessage: false,
  warnHtmlMessage: false,
})

export default i18n
