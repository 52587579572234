import page from "./modules/page"
import invoices from "./modules/invoices"
import product from "./modules/product"
import recipients from "./modules/recipients"
import support from "./modules/support"
import orders from "./modules/orders"
import orderItem from "./modules/order-item"
import sharedOrder from "./modules/shared-order"
import settings from "./modules/settings"
import user from "./modules/user"

import { createStore } from "vuex"

export default createStore({
  modules: {
    page,
    invoices,
    product,
    recipients,
    support,
    orders,
    orderItem,
    sharedOrder,
    settings,
    user,
  },
})
