const state = {
  finalizedInvoice: null,
}

const getters = {}

const mutations = {
  setFinalizedInvoice(state, invoice) {
    state.finalizedInvoice = invoice
  },
}

const actions = {
  setFinalizedInvoice({ commit }, invoice) {
    commit("setFinalizedInvoice", invoice)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
