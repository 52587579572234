const state = {
  cartOpen: false,
  supportOpen: false,
}

const getters = {
  cartOpen: state => {
    return state.cartOpen
  },
  supportOpen: state => {
    return state.supportOpen
  },
}

const mutations = {
  openCart: state => (state.cartOpen = true),
  closeCart: state => (state.cartOpen = false),
  setSupportOpen(state, open) {
    state.supportOpen = open
  },
}

const actions = {
  toggleSupport: ({ commit }, state) => commit("setSupportOpen", state),
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
