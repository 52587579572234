import { userLang } from "@/helpers/globals"
import httpClient from "@/helpers/http"
import { zRoute } from "@/helpers/route-helpers"

const transformEmployee = employee => {
  return {
    price: employee.price === 0 ? 0 : employee.price || "",
    from: employee.from || "",
    to:
      (employee.added_manually ? employee.to : `${employee.first_name} ${employee.last_name}`) ||
      "",
    message: employee.message || "",
    detailed_reference: employee.detailed_reference || "",
    locale: employee.locale || "",
    email: employee.email || "",
    delivery_date: employee.delivery_date || "",
    delivery_time: employee.delivery_time || "",
    thankyou_video: employee.thankyou_video || null,
    employee_address_id: (employee.address && employee.address.id) || "",
    recipient_first_name: employee.first_name || "",
    recipient_last_name: employee.last_name || "",
    street: (employee.address && employee.address.street) || "",
    street_number: (employee.address && employee.address.street_number) || "",
    bus: (employee.address && employee.address.bus) || "",
    zipcode: (employee.address && employee.address.zip_code) || "",
    city: (employee.address && employee.address.city) || "",
    country: (employee.address && employee.address.country) || "",

    // additional fields
    address_id: null,
    id: `emp-${employee.id}`,
    employee_id: employee.id ?? null,
    excel: !!employee.excel,

    // conditional fields
    ...(!employee.added_manually
      ? {
          group_id: employee.group_id,
          group_name: employee.group ? employee.group.name : "",
        }
      : {}),
  }
}

const state = {
  beingAdded: [],
  recipients: [],
  selectedRecipients: [],
  selectedTeams: [],
  confirmed: false,
  editing: false,
  recipientListErrors: [],
  deliveryDateInfo: [],
}

const getters = {
  isConfirmed: state => state.confirmed,
  recipientList: state => state.recipients.filter(recipient => !recipient.excel),
  excelRecipientList: state => state.recipients.filter(recipient => recipient.excel),
  recipientToEdit: state => state.recipients.find(recipient => recipient.id === state.editing),
  employeeIds: state => state.recipients.map(recipient => recipient.employee_id).filter(Boolean),
}

const mutations = {
  reset: state => {
    state.beingAdded = []
    state.recipients = []
    state.selectedRecipients = []
    state.selectedTeams = []
    state.confirmed = false
    state.editing = false
    state.recipientListErrors = []
  },
  addRecipient: ({ recipients }, recipient) => {
    recipients.unshift(recipient)
  },
  addEmployees: (state, employees) => {
    state.recipients = [...employees.map(transformEmployee), ...state.recipients]
  },
  setRecipients: (state, recipients) => (state.recipients = recipients),
  mapEmployeesAndSet: (state, employees) => {
    state.recipients = employees.map(employee => transformEmployee(employee))
  },
  removeTeams: (state, ids) => {
    state.recipients = state.recipients.filter(recipient => {
      return !recipient.group_id || (recipient.group_id && !ids.includes(recipient.group_id))
    })
  },
  updateBeingAdded: (state, data) => {
    state.beingAdded = data
  },
  toggleEmployee: (state, { checked, id }) => {
    if (checked) {
      if (state.selectedRecipients.includes(id)) return
      state.selectedRecipients = [...state.selectedRecipients, id]
    } else {
      const index = state.selectedRecipients.indexOf(id)
      if (index === -1) return
      const arr = JSON.parse(JSON.stringify(state.selectedRecipients))
      arr.splice(index, 1)
      state.selectedRecipients = arr
    }
  },
  removeSelectedRecipient: (state, id) => {
    const i = state.selectedRecipients.indexOf(id)
    if (i > -1) state.selectedRecipients.splice(i, 1)
  },
  selectAll: (state, checked) => {
    if (checked) {
      state.selectedRecipients = state.recipients.map(recipient => recipient.id)
    } else {
      state.selectedRecipients = []
    }
  },
  setRecipientData: (state, { id, attribute, value, customerField }) => {
    const index = state.recipients.findIndex(recipient => recipient.id === id)
    if (!customerField) {
      state.recipients[index][attribute] = value
    } else {
      state.recipients[index]["customer_fields"][attribute] = value
    }
  },
  massUpdate: (state, { attribute, value }) => {
    state.recipients.forEach(recipient => {
      if (state.selectedRecipients.includes(recipient.id)) {
        recipient[attribute] = value
      }
    })
  },
  confirmList: state => (state.confirmed = true),
  discardList: state => (state.confirmed = false),
  edit: (state, id) => (state.editing = id),
  cancelEdit: state => (state.editing = false),
  setSelectedTeams: (state, teams) => {
    state.selectedTeams = teams
  },
  resetSelection: state => {
    state.employees = []
    state.selectedRecipients = []
    state.selectedTeams = []
  },
  setEmployeeListErrors: (state, errors) => (state.recipientListErrors = errors),
  loadFromPersonalizationData: (state, data) => {
    state.employees = data.employees
    state.selectedTeams = data.selectedTeams
  },
  setDeliveryDateInfo: (state, data) => {
    const index = state.deliveryDateInfo.findIndex(info => info.key === data.key)

    if (index !== -1) {
      state.deliveryDateInfo.splice(index, 1, data)
    } else {
      state.deliveryDateInfo.push(data)
    }
  },
}

const actions = {
  addTeams: ({ state, commit, getters }, ids) => {
    const toAdd = ids.filter(id => !state.beingAdded.includes(id))

    if (!toAdd.length) return

    commit("updateBeingAdded", [...state.beingAdded, ...toAdd])

    httpClient
      .get(`/${userLang}/get-employees-for-group?ids=${JSON.stringify(toAdd)}`)
      .then(response => {
        const filteredEmployees = response.data.filter(employee => {
          return !getters.employeeIds.includes(employee.id)
        })
        commit("addEmployees", filteredEmployees)
      })
      .catch(() => console.log("An error occurred while trying to add employees from the team."))
      .finally(() => {
        commit(
          "updateBeingAdded",
          state.beingAdded.filter(id => !toAdd.includes(id)),
        )
      })
  },
  addAllEmployees: ({ commit, getters, rootGetters }) => {
    httpClient
      .get(`/${userLang}/employees/all`)
      .then(response => {
        const filteredEmployees = response.data
          .filter(employee => {
            return !getters.employeeIds.includes(employee.id)
          })
          .map(employee => {
            if (rootGetters["product/hasFixedPrice"]) {
              employee.price = rootGetters["product/fixedPrice"]
            }
            return employee
          })
        commit("addEmployees", filteredEmployees)
      })
      .catch(() => console.log("An error occurred while trying to add all employees."))
  },
  removeRecipient: ({ state, commit }, id) => {
    try {
      commit(
        "setRecipients",
        state.recipients.filter(recipient => recipient.id !== id),
      )
      commit("removeSelectedRecipient", id)
      return Promise.resolve(true)
    } catch (err) {
      return Promise.reject(err)
    }
  },
  removeRecipientByEmployeeId: ({ state, commit }, id) => {
    const index = state.recipients.findIndex(recipient => recipient.employee_id === id)

    if (index === -1) {
      return Promise.resolve(false)
    }

    state.recipients.splice(index, 1)
    commit("removeSelectedRecipient", id)
    return Promise.resolve(true)
  },
  cancelEdit: ctx => {
    ctx.commit("cancelEdit")
  },
  getDeliveryDateInfo: ({ commit, state }, { giftId, country }) => {
    const key = `${giftId}-${country}`

    if (!country) {
      return
    }

    const existing = state.deliveryDateInfo.find(info => info.key === key)

    if (existing) {
      return
    }

    httpClient
      .get(
        zRoute("gifts.get-delivery-date-info", {
          gift: giftId,
          country,
        }),
      )
      .then(({ data }) => {
        const key = `${giftId}-${country}`
        const value = {
          earliestDeliveryDate: data.earliest_delivery_date,
          deliveryDays: data.delivery_days,
          excludedDeliveryDates: data.excluded_delivery_dates,
        }

        commit("setDeliveryDateInfo", {
          key,
          value,
        })
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
